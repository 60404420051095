import axios from "@/axios";

const getters = {};

const state = {};

const mutations = {};

export const SIGNIN = "signIn";
export const SIGNOUT = "signOut";
export const SIGNUP = "signUp";

const actions = {
  [SIGNIN](context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/login", payload)
        .then(() => {
          if (process.env.NODE_ENV === "production") {
            window.location.href = "https://streamer.fun";
          } else {
            window.location.href = "http://2streamer.local";
          }
          // context.dispatch(GET_USER).then(() => {
          //   resolve(response.data);
          // });
        })
        .catch((data) => {
          reject(data.response);
        });
    });
  },
  // [SIGNOUT](context, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post("/signout", payload)
  //       .then(() => {
  //         window.location.href = "http://panel.marketapp.local";
  //         // context.dispatch(GET_USER).then(() => {
  //         //   resolve(response.data);
  //         // });
  //       })
  //       .catch((data) => {
  //         reject(data.response);
  //       });
  //   });
  // },
  [SIGNUP](context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/register", payload)
        .then(() => {
          if (process.env.NODE_ENV === "production") {
            window.location.href = "https://streamer.fun";
          } else {
            window.location.href = "http://2streamer.local";
          }
          // context.dispatch(GET_USER).then(() => {
          //   resolve(response.data);
          // });
        })
        .catch((data) => {
          reject(data.response);
        });
    });
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
