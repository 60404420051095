import { createRouter, createWebHistory } from "vue-router";
import SigninView from "../views/SigninView";

const routes = [
  {
    path: "/",
    name: "login",
    component: SigninView,
  },
  {
    path: "/login",
    redirect: () => {
      return { path: "/" };
    },
  },
  {
    path: "/register",
    name: "register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ "../views/SignupView"),
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
