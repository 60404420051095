import { createApp } from "vue";
import App from "./App.vue";
import { createHead } from "@vueuse/head";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

require("@tabler/core");
require("@tabler/core/dist/css/tabler.min.css");
require("./styles/main.scss");

const head = createHead({
  titleTemplate: (title) => `${title} - StreamerFun`,
});

const app = createApp(App);
app.use(store).use(router).use(head);
app.mount("#app");
