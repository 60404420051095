import axios from "@/axios";

const getters = {
  user(state) {
    return state.user;
  },
};

const state = {
  user: null,
};

export const SET_USER = "setUser";

const mutations = {
  [SET_USER](state, payload) {
    state.user = payload;
  },
};

export const GET_USER = "getUser";

const actions = {
  [GET_USER](context) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user")
        .then((response) => {
          if (response.data && Object.keys(response.data).length > 0) {
            context.commit(SET_USER, response.data);
            resolve(response.data);
          } else {
            context.commit(SET_USER, false);
            resolve(false);
          }
        })
        .catch((data) => {
          reject(data.response);
        });
    });
  },
};

export default {
  getters,
  state,
  mutations,
  actions,
};
