<template>
  <auth-layout>
    <template v-slot:after>
      Не зарегистрированы?
      <router-link :to="{ name: 'register' }" tabindex="-1">Создать аккаунт</router-link>
    </template>

    <template v-slot:default>
      <h2 class="card-title text-center mb-4">Авторизация</h2>
      <div class="mb-3">
        <label class="form-label">Почта</label>
        <input
          type="email"
          class="form-control"
          :class="{ 'is-invalid': errors && errors.email }"
          v-model="form.email"
          placeholder="Введите электронную почту"
        />
        <div class="invalid-feedback" v-if="errors && errors.email">
          <span v-for="(e, index) in errors.email" :key="'error_email_' + index">{{ e }}</span>
        </div>
      </div>

      <div class="mb-2">
        <label class="form-label">
          Пароль
          <!--          <span class="form-label-description">
            <a href="#">Я забыл пароль</a>
          </span>-->
        </label>
        <input
          type="password"
          class="form-control"
          :class="{ 'is-invalid': errors && errors.password }"
          v-model="form.password"
          placeholder="Введите пароль"
          autocomplete="off"
        />
        <div class="invalid-feedback" v-if="errors && errors.password">
          <span v-for="(e, index) in errors.password" :key="'error_password_' + index">{{
            e
          }}</span>
        </div>
      </div>
      <div class="mb-2">
        <label class="form-check">
          <input type="checkbox" v-model="form.remember" class="form-check-input" />
          <span class="form-check-label">Запомнить меня на этом устройстве</span>
        </label>
      </div>
      <div class="form-footer">
        <button type="button" class="btn btn-primary w-100" @click="signIn">Войти</button>
      </div>
    </template>

    <!--    <template v-slot:additional>
      <div class="hr-text">или</div>
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-auto">
            <a href="#" class="btn btn-white w-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-brand-vk text-vk"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M10 6h2v12c-4.5 -1 -8 -6.5 -9 -12"></path>
                <path d="M20 6c-1 2 -3 5 -5 6h-3"></path>
                <path d="M20 18c-1 -2 -3 -5 -5 -6"></path>
              </svg>
              VK
            </a>
          </div>
          <div class="col">
            <a href="#" class="btn btn-white w-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-brand-telegram text-tabler"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
              </svg>
              Telegram
            </a>
          </div>
          <div class="col-auto">
            <a href="#" class="btn btn-white w-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-brand-google text-google"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M17.788 5.108a9 9 0 1 0 3.212 6.892h-8"></path>
              </svg>
              Google
            </a>
          </div>
        </div>
      </div>
    </template>-->
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import { SIGNIN } from "@/store/modules/auth";
import { useHead } from "@vueuse/head";

export default {
  name: "SigninView",
  components: { AuthLayout },
  data() {
    return {
      errors: [],
      form: {
        email: "",
        password: "",
        remember: "",
      },
    };
  },
  methods: {
    signIn() {
      this.errors = {};
      this.$store
        .dispatch(SIGNIN, { ...this.form })
        .then((res) => {
          console.log(res);
        })
        .catch((res) => {
          if (res.status === 204) {
            // success
          } else {
            alert(res.data.message);
            this.errors = res.data.errors;
          }
        });
    },
    clearError(error) {
      if (Object.prototype.hasOwnProperty.call(this.errors, error)) {
        delete this.errors[error];
      }
    },
  },
  beforeCreate() {
    useHead({
      title: "Авторизация",
      meta: [
        {
          name: "description",
          content: "Авторизация на сайте StreamerFun.",
        },
      ],
    });
  },
};
</script>

<style scoped></style>
