import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = "http://auth.2streamer.local/";
}
// axios.defaults.withCredentials = true;

const token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
  /* eslint-disable no-console */
  console.error("CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token");
  /* eslint-enable no-console */
}

export default axios;
