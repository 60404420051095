<template>
  <div class="d-flex flex-column">
    <div class="page page-center">
      <div class="container-tight py-4">
        <div class="text-center mb-2">
          <a href="/" class="navbar-brand navbar-brand-autodark">
            <img src="../assets/logo.png" height="128" alt="" />
          </a>
        </div>
        <div class="card card-md">
          <form class="card-body" autocomplete="off">
            <slot></slot>
          </form>
          <slot name="additional"></slot>
        </div>
        <div class="text-center text-muted mt-3">
          <slot name="after"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style scoped></style>
